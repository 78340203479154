<script lang="ts" context="module">
  // Local Typescript types
  type TimeSelectorOption = { name: string; filter: string };

  // Local constants
  const storage_key = "drsc_selected_time_name";

  // Local helpers
  function getFilter(daysBack: number): string {
    const now = new Date();
    const pastDate = new Date(now.setDate(now.getDate() - daysBack));
    pastDate.setHours(0, 0, 0, 0); // Reset to midnight (start of the day) in local time
    const isoString = pastDate.toISOString();
    return isoString;
  }
  function getFilterYTD(): string {
    const now = new Date(); // Get the current date and time
    const startOfYear = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0); // Create a new date object for January 1st of the current year
    const isoString = startOfYear.toISOString(); // Convert to ISO 8601 string
    return isoString;
  }

  // Time Range Options
  const time_selectors: Array<TimeSelectorOption> = [
    {
      name: "This Year",
      filter: getFilterYTD(),
    },
    { name: "90 Days", filter: getFilter(90) },
    { name: "30 Days", filter: getFilter(30) },
    { name: "7 Days", filter: getFilter(7) },
    { name: "Today", filter: getFilter(0) },
  ];
  function getSelectedOptionFromStorage(): TimeSelectorOption {
    const name_from_storage = localStorage.getItem(storage_key);
    if (!name_from_storage) return time_selectors[time_selectors.length-1];
    const found_option = time_selectors.find(
      (s) => s.name === name_from_storage,
    );
    return found_option || time_selectors[1];
  }
  export function getSelectedDateRangeFilter(
    selection?: TimeSelectorOption,
  ): string {
    if (!selection) selection = getSelectedOptionFromStorage();
    return selection.filter;
  }
</script>

<script lang="ts">
  // Include our external dependencies.
  // ...

  // Component props
  export let date_range_filter: string = getSelectedDateRangeFilter();

  // Component Internal Logic
  let selected_option: TimeSelectorOption = getSelectedOptionFromStorage();
  $: {
    date_range_filter = getSelectedDateRangeFilter(selected_option);
    localStorage.setItem(storage_key, selected_option.name);
  }
</script>

<main data-component="DateRangeSelector">

  <!-- Select for Smaller Screens -->
  <select class="form-select d-md-none" aria-label="Date Range Selector" bind:value={selected_option}>
    {#each time_selectors as t}
      <option value={t}>{t.name}</option>
    {/each}
  </select>

  <!-- Button Group for Larger Screens -->
  <div
    class="btn-group d-none d-md-inline-flex"
    role="group"
    aria-label="Date Range Selector"
    data-component="DateRangeSelector"
  >
    {#each time_selectors as t, i}
      {@const id = `mdtrs${i}`}
      <input
        type="radio"
        class="btn-check"
        name={id}
        {id}
        autocomplete="off"
        bind:group={selected_option}
        value={t}
      />
      <label class="btn btn-outline-secondary" for={id}>{t.name}</label>
    {/each}
  </div>
</main>
