<script lang="ts">
  // Include our external dependencies.
  import i18n from '../../lib/i18n';
  import { prettyDateTime } from '../../lib/time';

  // Component Props
  export let list: Array<Object> = [];
  export let formatters:{
    [propToFormat:string]: (value:any)=>string;
  }|null = null;

  // Handle different print values - $, time, etc.
  function printValue (name:string, value:any):string {
    // passed-in formatters
    if (formatters) {
      if (formatters[name])
        return formatters[name](value);
    }
    // time
    if (name.indexOf("_at") !== -1) return prettyDateTime(value);
    
    return value;
  }
</script>

<main data-component="ListGroupTable">
  {#if list && list.length}
    <ul class="list-group">
      {#each list as item}
        {@const row_entries = Object.entries(item)}
        <li
          class="list-group-item d-flex justify-content-between align-items-start"
        >
          <div class="row w-100">
            {#each row_entries as [prop, val], i}
              {@const tprop = $i18n.t(`dbfield.${prop}`, prop)}
              <div
                class="col-auto col-md col-md pb-1 pb-md-0 text-truncate"
                title={`${tprop} = ${val}`}
                class:col-md-auto={i === row_entries.length - 1}
              >
                <div class="fw-bold text-capitalize">{tprop}</div>
                {printValue(prop, val)}
              </div>
            {/each}
          </div>
        </li>
      {/each}
    </ul>
  {/if}
</main>
