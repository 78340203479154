<script lang="ts">
  // Include our external dependencies.
  import { createEventDispatcher } from 'svelte';
  import api, { parseAuthCookie } from "../../lib/api";
  import { showToast, user } from "../../lib/store";
  import LogoSVG from "99dev-bootstrap/assets/99dev_logo_pink_resp_text.svg?raw";

  // Read some environment variables
  const demo_url = import.meta.env.VITE_99DEVDEMOURL;
  const support_url = import.meta.env.VITE_99DEV_SUPPORT_LINK;
  const www_url = import.meta.env.VITE_99DEVWWWURL;

  // Instantiate the component event dispatcher
  const dispatch = createEventDispatcher();

  let form_is_disabled = false;
  let username = "";
  let pass = "";

  function form_submit (event:SubmitEvent) {
    event.preventDefault();
    form_is_disabled = true;
    api.POST("/session", {
      body: { username, pass },
    }).then(function ({ data, error, response }) {
      if (error) {
        showToast({hed:`These credentials were not accepted`,colorClassname:"text-bg-danger"});
        return false;
      }
      $user = parseAuthCookie();
      showToast({hed:`You are now signed in as ${$user.username}`,colorClassname:"text-bg-success"});
      dispatch("sign-in", $user);
    }).finally(function () {
      form_is_disabled = false;
    });
  }
</script>
<style>
  .form-signin {
    max-width: 20em;
  }
</style>

<main class="d-flex align-items-center py-4 bg-body-tertiary vh-100 vw-100">
  <main class="form-signin m-auto">

    {#if $user}
      <div class="mb-5">
        <div class="alert alert-secondary" role="alert">
          FYI, you are currently signed in as <strong>{$user.username}</strong>.<br><a href="/#/">Click here</a> to return to your dashboard.
        </div>
      </div>
    {/if}
    <form on:submit={form_submit}>
      <fieldset disabled={form_is_disabled}>
        <div class="text-center">
          <span class="text-body">
            {@html LogoSVG}
          </span>
          <h1 class="h3 my-3 fw-normal">Please sign in</h1>
        </div>

        <div class="form-floating">
          <input type="text" class="form-control rounded-bottom-0" id="floatingInput" placeholder="Username" bind:value={username} required>
          <label for="floatingInput">Username</label>
        </div>
        <div class="form-floating">
          <input type="password" class="form-control rounded-top-0" id="floatingPassword" placeholder="Password" bind:value={pass} required>
          <label for="floatingPassword">Password</label>
        </div>

        <!-- <div class="form-check text-start my-3">
          <input class="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            Remember me
          </label>
        </div> -->
        <button class="btn btn-primary w-100 py-2 mt-3" type="submit">Sign in</button>
        <a class="btn btn-link w-100 py-2 mt-3 mt-md-5" href="#/create-account">Create an Account</a>
        <a class="btn btn-link w-100 py-2 mt-3" href={demo_url}>Check Out the Demo Dashboard</a>
        <a class="btn btn-link w-100 py-2 mt-3" href={support_url}>Contact Support</a>
        <a class="btn btn-link w-100 py-2 mt-3" href={www_url}>About 99dev</a>
        <p class="mt-5 mb-3 text-body-secondary">&copy; 2024</p>
      </fieldset>
    </form>
  </main>
</main>