// Include external dependencies.
import Cookies from "js-cookie";
import createClient from "openapi-fetch";
import { type components, type paths } from "../99dev-api";
export type { paths, components } from "../99dev-api";

// Expand our typescript types
type UnsecuredSessionDetails = components["schemas"]["UnsecuredSessionDetails"];

// Create an instance of the OpenAPI-Fetch client
const client = createClient<paths>({
  baseUrl: import.meta.env.VITE_99DEVAPIURL,
  credentials: "include",
  mode: "cors",
});
export default client;

export function parseAuthCookie (cookieName = "99DEVTOKEN"):null|UnsecuredSessionDetails {
  try {
    const raw_cookie = Cookies.get(cookieName) || "";
    const cookie_json = JSON.parse(raw_cookie);
    return cookie_json || null;
  } catch (err) {
    console.error(`Cookie could no be parsed.`, err);
    return null;
  }
}

//TODO: Handle Unauthorized Errors from the API (401)
