<script lang="ts">
  // Include our external dependencies.
  import CheckSVG from "bootstrap-icons/icons/check2-circle.svg?raw";

  // Component Props
  export let className = "";
  export let onDismiss:()=>any;
  export let ico = CheckSVG;
  export let hed = "Jumbotron Title";
  export let dek = "This faded back jumbotron is useful for placeholder content. It's also a great way to add a bit of context to a page or section when no content is available and to encourage visitors to take a specific action.";
</script>

<style>
  .icofgr :global(svg) {
    width: 48px;
    height: auto;
  }
</style>


<div class="container {className}">
  <div
    class="position-relative p-3 p-md-5 text-center text-muted bg-body border border-dashed rounded-5"
  >
    {#if onDismiss}
      <button
        type="button"
        class="position-absolute top-0 end-0 p-3 m-3 btn-close bg-secondary bg-opacity-10 rounded-pill"
        aria-label="Close"
        on:click={onDismiss}
      ></button>
    {/if}
    <figure class="icofgr">
      {@html ico}
    </figure>
    <h1 class="text-body-emphasis">{hed}</h1>
    {#if dek}
      <p class="col-lg-6 mx-auto mb-4">{@html dek}</p>
    {/if}
    <slot/>
  </div>
</div>
