<script lang="ts">
  import { route0 } from "mcgregor-utils/svelte-router";
  import { user } from "../lib/store";
  import Home from "./pages/home.svelte";
  import SignIn from "./pages/sign-in.svelte";
  import CreateAccount from "./pages/create-account.svelte";
  import NotFound from "./pages/not-found.svelte";
  import Profile from "./pages/profile.svelte";
  import Toasts from "./atoms/toasts.svelte";

  function redirectHome () {
    if ($route0 === "sign-in") {
      window.location.hash = "#/";
    }
  }
</script>

<main data-component="App">

  <!-- Home page -->
  {#if !$route0 || $route0 === "mian"}
    {#if $user}
      <Home/>
    {:else}
      <SignIn />
    {/if}

  <!-- Sign In -->
  {:else if $route0 === "sign-in"}
    <SignIn on:sign-in={redirectHome} />

  <!-- Create Account -->
  {:else if $route0 === "create-account"}
    <CreateAccount />
  
    <!-- Profile -->
  {:else if $route0 === "profile"}
    {#if $user}
      <Profile />
    {:else}
      <SignIn />
    {/if}

  <!-- Catch All 404 -->
  {:else}
    <NotFound />
    
  {/if}

  <Toasts />
</main>