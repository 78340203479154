<script lang="ts">
  // Include our external dependencies.
  import { format } from "d3-format";
  import { formatNumberAbbreviation } from "../../lib/numb";
  import { type MianHeadlines } from "../../lib/generate-chart-data";

  // Component Props
  export let headlines:MianHeadlines;
</script>
<div class="mb-5">
  <div class="bg-body-tertiary rounded-3">
    <div class="row text-center">
      <div class="col-6 col-md my-3" title={headlines.total_page_views.toString()}>
          <span class="d-block h1">{formatNumberAbbreviation(headlines.total_page_views)}</span>
          <span class="d-block m-0">Total Page Views</span>
      </div>
      <div class="col-6 col-md my-3" title={headlines.total_visits.toString()}>
          <span class="d-block h1">{formatNumberAbbreviation(headlines.total_visits)}</span>
          <span class="d-block m-0">Total Visits</span>
      </div>
      <div class="col-6 col-md my-3" title={headlines.total_bounces.toString()}>
          <span class="d-block h1">{formatNumberAbbreviation(headlines.total_bounces)}</span>
          <span class="d-block m-0">Total Bounces</span>
      </div>
      <div class="col-6 col-md my-3">
          <span class="d-block h1">{format(".0%")(headlines.total_bounces / headlines.total_visits)}</span>
          <span class="d-block m-0">Bounce Rate</span>
      </div>
    </div>
  </div>
</div>