<script lang="ts">
  // Include our external dependencies.
  import { Chart, colors } from "../../lib/chart";
  import { isoToTimestamp } from "../../lib/time";
  import { onMount } from "svelte";

  // Component Props/API
  export let chart_data;

  // Prepare the Chart.js graph
  let canvasEl: HTMLCanvasElement;
  onMount(() => {
    const page_names = Object.keys(chart_data.categories.urls)
      .sort((arr) => arr.__sum)
      .slice(0, 8);

    const chart = new Chart(canvasEl, {
      type: "line",
      data: {
        labels: chart_data.dates.map(isoToTimestamp),
        datasets: page_names.map((name, i) => {
          return {
            data: Array.from(
              { length: chart_data.dates.length },
              (v, i) => chart_data.categories.urls[name][i] || 0,
            ),
            label: name,
            backgroundColor: colors[i],
            borderColor: colors[i],
            cubicInterpolationMode: "monotone",
            tension: 0.5,
          };
        }),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false, // Allows you to control the chart size
        plugins: {
          legend: {
            position: "top",
          },
          colors: {
            enabled: false,
          },
          title: {
            display: true,
            text: "Page Popularity",
          },
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            type: "timestack",
            grid: {
              display: false,
            },
            ticks: {
              padding: 0, // Reduce padding to avoid white space
            },
          },
          y: {
            stacked: true,
            beginAtZero: true, // Ensure the Y-axis starts at zero
            ticks: {
              padding: 1,
              backdropPadding: 0,
              showLabelBackdrop: true,
              z: 1,
            },
            grid: {
              display: false,
              drawTicks: false,
            },
            border: {
              display: false,
            }
          },
        },
      },
    });

    // // Resize listener to dynamically adjust chart
    // window.addEventListener('resize', function () {
    //   chart.update();
    // });

    // // Set a minimum height for the canvas
    // canvasEl.style.minHeight = "300px"; // Or any other value

    return () => chart.destroy();
  });
</script>

<canvas bind:this={canvasEl} />

<style>
  canvas {
    max-height: 600px;
    min-height: 386px;
  }
</style>
