<script lang="ts">
  // Include our external dependencies.
  import { queryParameters as qps } from "mcgregor-utils/svelte-router";
  import {
    user,
    fund_historyP,
    loadProfile,
    loadFundHistory,
    purchase_historyP,
    loadPurchaseHistory,
  } from "../../lib/store";
  import i18n from "../../lib/i18n";
  import { prettyDateTime } from "../../lib/time";
  import { formatMoney, formatNumberAbbreviation } from "../../lib/numb";
  import GlobalHeader from "../organisms/global-header.svelte";
  import MianBalanceProgressBar from "../molecules/mian-balance-progress-bar.svelte";
  import ListGroupTable from "../atoms/list-group-table.svelte";
  import LoadMoreButton from "../atoms/load-more-button.svelte";

// Read environment variables
  const docs_link = import.meta.env.VITE_99DEV_DOCS_LINK;
  const checkoutLink = `${import.meta.env.VITE_99DEVAPIURL}/payment-processor/checkout-session`;

  // Bind URL Properties
  $: back_to_sites_link = ["#/", $qps["back-to-site"] || ""].join("");

  // Load additional user data into the store.
  loadProfile();
  loadFundHistory();
  loadPurchaseHistory();

  $:prettyUser = makePrettyUser($user);
  function makePrettyUser(u) {
    return {
      ...u,
      wallet_balance: formatMoney(u.wallet_balance || 0),
      mian_balance: u.mian_balance || 0,
      created_at: u.created_at ? prettyDateTime(u.created_at) : u.created_at,
    };
  }
</script>

<main class="pb-5">
  <GlobalHeader>
    <a href={back_to_sites_link} class="btn btn-outline-primary" slot="left">
      ←<span class="d-none d-sm-inline">&nbsp;Back to Sites</span></a>
  </GlobalHeader>

  <div class="container-xl">
  <div class="row">
    <!-- Core Page Contents -->
    <div class="col">
      <div class="row mb-5 pt-2">
        <div class="col-md-6 my-2">
          <h4 class="mb-0">{prettyUser.wallet_balance}</h4>
          <span>Wallet Balance</span>
          <MianBalanceProgressBar />
        </div>
        <div class="col-md-6 my-2">
          <h4 class="mb-0">{prettyUser.mian_balance}</h4>
          <span>Micro Analytics Balance</span>
          <MianBalanceProgressBar />
        </div>
        <div class="col-12 my-2 order-md-5">
          <form class="d-grid gap-2 mt-2" action={checkoutLink} method="POST">
            <button
              class="btn btn-outline-secondary"
              type="submit">Add Wallet Funds</button
            >
          </form>
        </div>
      </div>

      <section class="mb-5">
        <h2>Account Details</h2>
        <p>Tell us about you as a person and our customer.</p>
        <ul class="list-group">
          {#each Object.entries(prettyUser) as [prop, val]}
            <li
              class="list-group-item d-flex justify-content-between align-items-start"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold text-capitalize">
                  {$i18n.t(`dbfield.${prop}`, prop)}
                </div>
                {val}
              </div>
            </li>
          {/each}
        </ul>
      </section>

      <!-- <section class="mb-5">
        <h2>Payment Methods</h2>
        <p>Lorem ipsum blah blah blah</p>
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title">
              You've not yet configured a payment method
            </h5>
            <p class="card-text">
              Please utilize our partner, Stripe, to configure a payment method
            </p>
            <button type="button" class="btn btn-primary"
              >Configure Payment Method</button
            >
          </div>
        </div>
      </section> -->

      <section>
        <h2>Billing</h2>
        <p class="d-block mb-4">
          Our pay-as-you-go services make it a breeze to keep your costs down.
          If you've got any questions about how it works, please check out our <a
            href={docs_link}>FAQ</a
          >.
        </p>

        <section class="my-4">
          <h4>Fund History</h4>
          <p>Times that you've added money to your wallet</p>
          {#await $fund_historyP}
            <div class="d-flex justify-content-center py-5">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          {:then { arr, loadMore }}
            {#if (arr && arr.length)}
              <ListGroupTable list={arr} formatters={{amount:formatMoney}} />
              <LoadMoreButton {loadMore} />
            {:else}
              <div class="text-muted text-center">
                <p>No Fund History Found</p>
              </div>
            {/if}
          {/await}
        </section>

        <section class="my-4">
          <h4>Purchase History</h4>
          <p>
            Times that you've purchased credits for services such as Micro
            Analytics
          </p>
          {#await $purchase_historyP}
            <div class="d-flex justify-content-center py-5">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          {:then { arr, loadMore }}
            {#if (arr && arr.length)}
              <ListGroupTable list={arr} formatters={{amount:formatMoney,credits:formatNumberAbbreviation}} />
              <LoadMoreButton {loadMore} />
            {:else}
              <div class="text-muted text-center">
                <p>No Purchase History Found</p>
              </div>
            {/if}
          {/await}
        </section>
      </section>
    </div>
  </div>
</div>
</main>
