<script lang="ts">
  // Include our external dependencies.
  import { type Writable, writable } from "svelte/store";
  import api from "../../lib/api";
  import { type components } from "../../lib/api";
  import DataTable from "../atoms/data-table.svelte";
  import SiteSettingsButton from "../molecules/site-settings-button.svelte";
  import {
    generateChartData,
    generateHeadlines,
    generatePagesPerSession,
  } from "../../lib/generate-chart-data";
  import MianStatJumbotron from "../atoms/mian-stat-jumbotron.svelte";
  import DateRangeSelector, {
    getSelectedDateRangeFilter,
  } from "../atoms/date-range-selector.svelte";
  import ViewModeSelector, { ViewMode } from "../atoms/view-mode-selector.svelte";
  import { type PaginatedArray, buildLoadMoreFunction } from "../../lib/store";
  import LoadMoreButton from "../atoms/load-more-button.svelte";
  import PviewChart from "../molecules/pview-chart.svelte";
  import DoughnutChart from "../atoms/doughnut-chart.svelte";
  import PagesChart from "../atoms/pages-chart.svelte";
  import Loader from '../atoms/loader.svelte';
  import ChartHorizontalBars from '../atoms/chart-horizontal-bars.svelte';
  import NoPageViewsFound from "../molecules/no-page-views-found.svelte";

  // Expand our Typescript types.
  type PageView = components["schemas"]["MianEvent"];
  type Site = components["schemas"]["Site"];
  type MianEventSummary = components["schemas"]["MianEventSummary"];

  // Component Props
  export let site: Site | undefined;

  // Date Filtering
  let date_range_filter = getSelectedDateRangeFilter();

  // View Mode Selector
  export let view_mode: ViewMode = ViewMode.Graph;

  // Load Mian Events
  let pageViewP: Writable<Promise<PaginatedArray<PageView>>|undefined> = writable();
  $: if (site && site.id && view_mode === ViewMode.Table) {
    pageViewP.set(api
      .GET("/mian/{site_id}", {
        params: {
          path: { site_id: site.id.toString() },
          query: date_range_filter
            ? { "filter[created_at][gte]": date_range_filter }
            : null,
        },
      })
      .then(function ({ data, error, response }) {
        return {
          arr: data?.data || [],
          loadMore: buildLoadMoreFunction(pageViewP, data?.links?.next),
        };
      })
    );
  }

  // Load Summary Data
  let summaryP: Promise<MianEventSummary>|undefined;
  $: if (site && site.id && view_mode === ViewMode.Graph) {
    summaryP = api
      .GET("/mian/{site_id}/summary", {
        params: {
          path: { site_id: site.id },
          query: date_range_filter
            ? { "filter[created_at][gte]": date_range_filter }
            : null,
        },
      })
      .then(({ data, error, response }) => {
        return data?.data || null;
      });
  }

  // Child Event Handler - When site data is cleared
  async function handleSiteDataClear () {
    // Wipe all of the site sub-data from memory
    summaryP = undefined;
    pageViewP = writable();
  }
</script>

<main data-component="MianDashboard">
  {#if site}
    <!-- Toolbar -->
    <div class="container-xl">
      <div class="row pb-2 gx-2 gx-lg-3">
        <!-- Date Range -->
        <div class="col">
          <DateRangeSelector bind:date_range_filter />
        </div>
        <div class="col-auto">
          <ViewModeSelector {view_mode} on:viewModeChange />
        </div>
        <slot name="toolbar-right">
          <div class="col-auto">
            <SiteSettingsButton {site} on:clear={handleSiteDataClear} on:delete />
          </div>
        </slot>
      </div>
    </div>

    <!-- Summary Chart Loader -->
    {#if view_mode === ViewMode.Graph}
      {#await summaryP}
        <Loader />
      {:then summary}
        {#if summary}
          {@const chart_data = generateChartData(summary)}
          {@const headlines = generateHeadlines(summary)}
          {#if headlines.total_page_views}
            <div class="container-xl">
              <MianStatJumbotron {headlines} />
            </div>
            <div class="container-xl px-0 px-md-3">
              <div class="row mb-3 gx-0 gx-lg-3 mx-0">
                <div class="col-12 mb-5">
                  <PagesChart {chart_data} />
                </div>
                <div class="col-12 mb-5">
                  <PviewChart {chart_data} />
                </div>
                <div class="col-12 col-sm-6 col-xl-4 mb-5">
                  <DoughnutChart {chart_data} field_name="oss" />
                </div>
                <div class="col-12 col-sm-6 col-xl-4 mb-5">
                  <DoughnutChart {chart_data} field_name="browsers" />
                </div>
                <div class="col-12 col-sm-6 col-xl-4 mb-5">
                  <DoughnutChart chart_data={generatePagesPerSession(chart_data)} field_name="pps" data_label="Sessions" />
                </div>
                <div class="col-12 col-sm-6 col-xl-4 mb-5">
                  <ChartHorizontalBars {chart_data} field_name="urls" />
                </div>
                <div class="col-12 col-sm-6 col-xl-4 mb-5">
                  <ChartHorizontalBars {chart_data} field_name="timezones" />
                </div>
                <div class="col-12 col-sm-6 col-xl-4 mb-5">
                  <ChartHorizontalBars {chart_data} field_name="referrers" />
                </div>
              </div>
            </div>
          {:else}
            <NoPageViewsFound site_uuid={site.uuid} />
          {/if}
        {:else}
          <NoPageViewsFound site_uuid={site.uuid} />
        {/if}
      {/await}
    {:else if view_mode === ViewMode.Table}
      <!-- Page View Table Loader -->
      {#await $pageViewP}
          <Loader />
      {:then { arr, loadMore }}
        {#if arr.length}
          <div class="container-xl">
            <DataTable rows={arr} />
          </div>
          <LoadMoreButton {loadMore} />
        {:else}
          <NoPageViewsFound site_uuid={site.uuid} />
        {/if}
      {/await}
    {/if}
  {:else}
    <div class="py-5 px-3 text-center">
      <h1>Site Not Found</h1>
    </div>
  {/if}
</main>
