<script lang="ts">
  // Include our external dependencies.
  import Modal from "bootstrap/js/dist/modal"; // Required for BS data attributes
  import { createEventDispatcher } from 'svelte';
  import { deleteSite, showToast, updateSite } from "../../lib/store";
  import type { components } from "../../lib/api";
  import api from "../../lib/api";
  import MianCode from "../atoms/mian-code.svelte";
  import GearSVG from "bootstrap-icons/icons/gear.svg?raw";

  // Expand our Typescript types.
  type Site = components["schemas"]["Site"];

  // Cache some environment variables.
  const docs_link = import.meta.env.VITE_99DEV_DOCS_LINK;

  // Instantiate the component event dispatcher
	const dispatch = createEventDispatcher();

  // Component Props
  export let site:Site|undefined;

  let input_name:string = "";
  let form_is_disabled = false;
  let modalEl:HTMLElement;

  async function saveChanges(event: SubmitEvent) {
    if (!site || !site.id) return;
    const bsmodal = Modal.getInstance(modalEl);
    form_is_disabled = true;
    await updateSite(site.id, {name:input_name}, site.uuid);
    form_is_disabled = false;
    if (bsmodal)bsmodal.hide();
  }


  // Click Handlers
  // - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - - |
  async function handleDelete(event: MouseEvent) {
    if (!site || !site.id) return;
    const promptTxt = window.prompt(`SITE DELETION CANNOT BE UNDONE! Confirm this deletion by entering the name of this site.`);
    if (promptTxt !== site.name) return;
    const bsmodal = Modal.getInstance(modalEl);
    await deleteSite(site.id, site.uuid); //TODO: Handle errors here!
    if (bsmodal)bsmodal.hide();
    showToast({
      hed: `${site.name} has been deleted`,
      colorClassname: "text-bg-success",
    });
    dispatch("delete");
  }

  function handleClear(event: MouseEvent) {
    if (!site || !site.id) return;
    const promptTxt = window.prompt(`DATA DELETION CANNOT BE UNDONE! Confirm this deletion by entering the name of this site.`);
    if (promptTxt !== site.name) return;
    api
      .DELETE("/mian/{site_id}", {
        params: { path: { site_id: site.id.toString() } },
      })
      .then(({ data, error, response }) => {
        const bsmodal = Modal.getInstance(modalEl);
        if (bsmodal)bsmodal.hide();
        dispatch("clear");
        showToast({
          hed: `All data for ${site.name} has been wiped`,
          colorClassname: "text-bg-success",
        });
      });
  }
</script>

<button
  type="button"
  class="btn btn-outline-secondary"
  data-bs-toggle="modal"
  data-bs-target="#siteSettingsModal"
>
  <span class="d-none d-lg-inline">Site Settings</span>
  <span class="d-lg-none">{@html GearSVG}</span>
</button>

<!-- Modal-->
<div
  class="modal modal-lg fade"
  id="siteSettingsModal"
  tabindex="-1"
  aria-labelledby="siteSettingsModalLabel"
  aria-hidden="true"
  bind:this={modalEl}
  on:show.bs.modal={() => (input_name=site?.name||"")}
>
  <form class="modal-dialog" on:submit|preventDefault={saveChanges}>
    <fieldset class="modal-content" disabled={form_is_disabled}>
      <div class="modal-header">
        <legend class="modal-title fs-5" id="siteSettingsModalLabel"
          >Site Settings - {site?.name}</legend
        >
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <fieldset class="mb-3" disabled={form_is_disabled}>
              <!-- Site Name -->
              <label for="asbc_name_input" class="form-label">Site Name</label>
              <input
                type="text"
                id="asbc_name_input"
                class="form-control"
                placeholder="something.com"
                required
                bind:value={input_name}
              />
            </fieldset>
          </div>
        </div>
        <div class="row"> 
          <div class="col">
            <span class="d-block mb-1">Tracking Snippet</span>
            <p>
              Place this tracking snippet on each page of your website. See our docs for <a href={docs_link}>installation via NPM</a>.
            </p>
            <MianCode site_uuid={site?.uuid} />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <span class="d-block mb-1">Danger Zone</span>
            <!-- <p class="mb-0 fst-italic">
              Place this tracking snippet on each page of your website.
            </p> -->
          </div>
          <div class="col-6">
            <div class="d-grid">
              <button class="btn btn-sm btn-outline-secondary hov-warn" type="button" on:click={handleClear}>Clear Site Data</button>
            </div>
          </div>
          <div class="col-6">
            <div class="d-grid">
              <button class="btn btn-sm btn-outline-secondary hov-danger" type="button" on:click={handleDelete}>Delete Site</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"
          >Close</button
        >
        <button type="submit" class="btn btn-outline-primary">Save changes</button>
      </div>
    </fieldset>
  </form>
</div>
