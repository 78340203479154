<script lang="ts">
  import LogoSVG from "99dev-bootstrap/assets/99dev_logo_pink_resp_text.svg?raw";
  import api, { parseAuthCookie } from "../../lib/api";
  import { user } from "../../lib/store";

  // Expand environment variables
  const policies_url = import.meta.env.VITE_99DEVPOLICIESURL;
  const support_url = import.meta.env.VITE_99DEV_SUPPORT_LINK;

  let form_is_disabled = false;
  let username = "";
  let pass = "";
  let passAgain = "";

  function form_submit (event:SubmitEvent) {
    event.preventDefault();
    form_is_disabled = true;
    api.POST("/users", {
      body: { username, pass },
    }).then(function ({ data, error, response }) {
      $user = parseAuthCookie();
      window.location.hash = "";
    }).catch(function () {
      form_is_disabled = false;
    });
  }
</script>

<style>
  .col.right {
    background-image: url("/images/wes-hicks-ZEZeFBLfiLs-unsplash.min.jpg");
    background-size: cover;
  }
</style>

<main class="vh-100 container-fluid">
  <div class="row h-100">
    <div class="col-lg-6 d-flex align-items-center">
      <form on:submit={form_submit} class="mx-auto">
        <fieldset disabled={form_is_disabled}>
          <div class="text-center">
            <span class="text-body d-block mb-5">
              {@html LogoSVG}
            </span>
            <h1 class="h3 mb-3 fw-normal">$1 a month</h1>
            <p>Start for free. Create an account to begin<br>with no-frill, pay-as-you-go analytics</p>
          </div>
  
          <div class="form-floating">
            <input type="text" class="form-control rounded-bottom-0" id="floatingInput" placeholder="Username" bind:value={username} required>
            <label for="floatingInput">Username</label>
          </div>
          <div class="form-floating">
            <input type="password" class="form-control rounded-top-0" id="floatingPassword" placeholder="Password" bind:value={pass} required>
            <label for="floatingPassword">Password</label>
          </div>
          <div class="form-floating">
            <input type="password" class="form-control rounded-top-0" id="floatingPassword" placeholder="Confirm Password" bind:value={passAgain} required>
            <label for="floatingPassword">Confirm Password</label>
          </div>
          <div class="form-text my-3">
            <span>By creating an account, you are agreeing to <a href={policies_url}>all 99dev policies.</a></span>
          </div>
  
          <!-- <div class="form-check text-start my-3">
            <input class="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              Remember me
            </label>
          </div> -->
          <button class="btn btn-outline-primary w-100 mt-4" type="submit">Create Account</button>
          <a class="btn btn-link w-100 py-2 mt-3" href="#/sign-in">Sign In</a>
          <a class="btn btn-link w-100 py-2 mt-3" href={support_url}>Contact Support</a>
          <p class="mt-5 mb-3 text-body-secondary">&copy; 2024</p>
        </fieldset>
      </form>
    </div>
    <div class="col d-none d-lg-block right"></div>
  </div>
</main>