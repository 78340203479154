<script lang="ts">
    import { showToast } from "../../lib/store";

  // Read Environment Variables
  const snippet_url = import.meta.env.VITE_99DEV_SNIPPET_URL;

  // Component Props
  export let site_uuid:string = "<Site ID>";

  // Da Code
  const snippet = `<script src='${snippet_url}' data-site-uuid='${site_uuid}' async>⌾/script>`.replace("⌾", "<"); // The ⌾ is because having the closing tag was freaking out my linters.

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(snippet);
      showToast({hed:"The snippet was copied to your clipboard"})
    } catch (err) {
      console.log("Failed to copy text to clipboard: ", err);
    }
  }
</script>

<style>
  .code {
    word-break: normal;
    word-break: break-word;
  }
</style>

<button class="btn btn-light user-select-auto" type="button" on:click={copyToClipboard}>
  <code class="code">{snippet}</code>
</button>