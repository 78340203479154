// Include our external dependencies.
import Chart from "chart.js/auto";
import tinycolor from 'tinycolor2';
import 'chartjs-scale-timestack';

// Define our custom color scheme
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// reference CSS variables from the DOM.
const rootStyles = getComputedStyle(document.documentElement);
const cPrim = rootStyles.getPropertyValue("--bs-primary");
const cSeco = rootStyles.getPropertyValue("--bs-secondary");
const cWarn = rootStyles.getPropertyValue("--bs-warning");
const cDang = rootStyles.getPropertyValue("--bs-danger");

// generate primary adjacent colors for the charts.
export const colors_gray = Array.from({length:8}, function map (_, i) {
  return tinycolor(cSeco).setAlpha(0.75).lighten(i * 17).toRgbString();
});

export const colorsSherbert = [
  tinycolor("#FF00A6").setAlpha(0.8).toRgbString(),
  tinycolor("#F7B267").setAlpha(0.8).toRgbString(),
  tinycolor("#F79D65").setAlpha(0.8).toRgbString(),
  tinycolor("#F4845F").setAlpha(0.8).toRgbString(),
  tinycolor("#E9F1F7").setAlpha(0.8).toRgbString(),
];

export const colorsWood = [
  tinycolor("#1A1423").setAlpha(0.8).toRgbString(),
  tinycolor("#3D314A").setAlpha(0.8).toRgbString(),
  tinycolor("#FF00A6").setAlpha(0.8).toRgbString(),
  tinycolor("#96705B").setAlpha(0.8).toRgbString(),
  tinycolor("#F5F0F6").setAlpha(0.8).toRgbString(),
];

export const colors = [
  tinycolor("#F72585").lighten(18).setAlpha(0.85).toRgbString(),
  tinycolor("#B5179E").lighten(18).setAlpha(0.85).toRgbString(),
  tinycolor("#7209B7").lighten(18).setAlpha(0.85).toRgbString(),
  tinycolor("#560BAD").lighten(18).setAlpha(0.85).toRgbString(),
  tinycolor("#480CA8").lighten(18).setAlpha(0.85).toRgbString(),
  tinycolor("#3A0CA3").lighten(18).setAlpha(0.85).toRgbString(),
  tinycolor("#3F37C9").lighten(18).setAlpha(0.85).toRgbString(),
  tinycolor("#4361EE").lighten(18).setAlpha(0.85).toRgbString(),
  tinycolor("#4895EF").lighten(18).setAlpha(0.85).toRgbString(),
  tinycolor("#4CC9F0").lighten(18).setAlpha(0.85).toRgbString(),
];

// Additional customizations
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// Chart.defaults.borderWidth = 4;
Chart.defaults.datasets.bar.borderWidth = 0;
Chart.defaults.datasets.line.borderWidth = 0;
Chart.defaults.datasets.line.fill = true;
Chart.defaults.datasets.line.pointStyle = false;

// Export it
export { Chart };