// Include external dependencies.
import { parseISO } from "date-fns/parseISO";
import { format } from "date-fns/format";

// Helper function to convert to a millisecond timestamp
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
export function isoToTimestamp(isoString: string): number {
  const date = parseISO(isoString);
  if (isNaN(date.getTime())) {
    throw new Error('Invalid ISO string');
  }
  return date.getTime();
}

export function prettyDateTime(isoString: string): string {
  const date = parseISO(isoString);
  return format(date, "MM/dd/yyyy hh:mmaaa");
}