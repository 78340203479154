/**
 * Calls a callback function when a specific timestamp is reached.
 * @param {string} targetTimestamp The target timestamp in ISO 8601 format.
 * @param {Function} callback The callback function to call when the timestamp is reached.
 */
export default function callAtTimestamp(targetTimestamp: string, callback: () => any): number {
  //
  // Convert the target timestamp to a Date object.
  const targetDate = new Date(targetTimestamp);
  const now = new Date();

  // Calculate the difference in milliseconds between the target date and now.
  const delay: number = targetDate.getTime() - now.getTime();

  // If the target time is in the past, immediately call the callback.
  if (delay <= 0) {
    console.warn('The target timestamp is in the past. Calling the callback immediately.');
    callback();
    return -1;
  }

  // Use setTimeout to wait until the target timestamp is reached, then call the callback.
  return setTimeout(callback, delay) as unknown as number;
}

