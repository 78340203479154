<script lang="ts">
  // Include our external dependencies.
  import { loadProfile, user } from "../../lib/store";

  // Load additional user data into the store.
  let is_loading = true;
  loadProfile().finally(() => {
    is_loading = false;
  });

  // Calculate the percentage.
  //TODO: This percentage should actually not be based on the static
  //      purchase amount because theoretically, the purchased amount
  //      could be different from the current live price.
  $: percent_filled = ($user && $user.mian_balance)
    ? (($user.mian_balance / import.meta.env.VITE_MIAN_CREDITS_PER_PURCHASE) * 100)
    : 0;
</script>
<style>
  .progress {
    height: .325em;
  }
</style>
<main class="progress mx-auto" role="progressbar" aria-label="Basic example" aria-valuenow={percent_filled} aria-valuemin="0" aria-valuemax="100">
  <div class="progress-bar {is_loading ? "progress-bar-striped progress-bar-animated" : ""}" style="width: {percent_filled}%"></div>
</main>