// include our external dependencies.
import { type Writable, writable } from "svelte/store";
import api from "./api";
import { components } from "../99dev-api";
import callAtTimestamp from "./call-at-timestamp";

// Expand our typescript types
export type Site = components["schemas"]["Site"];
type UnsecuredSessionDetails = components["schemas"]["UnsecuredSessionDetails"];
type FundHistoryItem = components["schemas"]["FundHistoryItem"];
type PurchaseHistoryItem = components["schemas"]["PurchaseHistoryItem"];
export type Sites = Map<string, Partial<Site>>;
export type PaginatedArray<T> = {
  arr: T[];
  loadMore?: (()=>Promise<void>) | null;
};


// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// User Stores & Helpers
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
export const user: Writable<null | UnsecuredSessionDetails> = writable();

export async function logout() {
  await api.DELETE("/session");
  user.set(null);
  window.sessionStorage.clear();
  sitesP.update($sitesP => {
    $sitesP.then(sites => sites.clear());
    return $sitesP;
  });
}

export function loadProfile(): Promise<void> {
  return api.GET("/users").then(({ data, error, response }) => {
    if (!data || !data.data) return;
    user.update(v => {
      if (!v) return v;
      return { ...v, ...data.data };
    });
  });
}

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// Site Stores
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
export const sitesP: Writable<Promise<Sites>> = writable();
let last_seen_username:string = "∞";
user.subscribe($user => {

  // Wipe any existing site data from memory.
  if (!$user || !$user.username) {
    sitesP.set(Promise.resolve(new Map()));
  }

  // Request sites data when a new user is detected.
  else if ($user?.username !== last_seen_username) {
    last_seen_username = $user.username;
    sitesP.set(api
      .GET("/sites")
      .then(function ({ data, error, response }) {
        if (!data || !data.data)
          return new Map();
        return new Map(data.data.map(site => [site.uuid, site]));
      }));
  }
});

export async function addSite(name: string):Promise<Site|undefined> {
  return api
    .POST("/sites", { body: { name } })
    .then(function ({ data, error, response }) {
      // manipulate the store
      sitesP.update(($sitesP) => {
        $sitesP.then(sites => {
          if (!data || !data.data) return sites;
          sites.set(data.data.uuid, data.data);
          sitesP.set(Promise.resolve(sites));
        });
        return $sitesP;
      });

      // return the new Site (with new ID)
      return data?.data;
    });
}

export async function deleteSite(site_id: number, site_uuid:string) {
  return api.DELETE("/sites/{site_id}", {
    params: {
      path: { site_id: site_id.toString() }
    }
  }).then(({ data, error, response }) => {
    // manipulate the store.
    sitesP.update($sitesP => {
      return $sitesP.then(sites => {
        sites.delete(site_uuid);
        return sites;
      });
    });
  });
}

export async function updateSite(site_id: number, updates: Partial<Site>, site_uuid: string) {
  return api.PATCH("/sites/{site_id}", {
    params: {
      path: { site_id: site_id.toString() },
    },
    body: updates,
  }).then(({data, error, response}) => {
    // manipulate the store
    sitesP.update($sitesP => {
      return $sitesP.then(sites => {
        const site = sites.get(site_uuid);
        return sites.set(site_uuid, Object.assign(site||{}, updates));
      });
    });
  });
}

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// Fund Stores
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
export const fund_historyP: Writable<Promise<PaginatedArray<FundHistoryItem>>> = writable();

export async function loadFundHistory(): Promise<void> {
  
  fund_historyP.set(api
    .GET("/funds")
    .then(function ({ data, error, response }) {
      return {
        arr: data?.data || [],
        loadMore: buildLoadMoreFunction(fund_historyP, data?.links?.next),
      };
    }));
}

export function buildLoadMoreFunction (storeToUpdate:Writable<Promise<PaginatedArray<any>>|undefined>, nextLink:string|undefined|null):(()=>Promise<void>) | null {
  if (!nextLink) return null;
  return async function () {
    return api
    // @ts-ignore
    .GET(nextLink)
    .then(({data, error, response}) => {
      storeToUpdate.update(prom => {
        if (!prom) return prom;
        return prom.then((paginatedArray) => {
          // @ts-ignore
          paginatedArray.arr.push(...data?.data);
          // @ts-ignore
          paginatedArray.loadMore = buildLoadMoreFunction(storeToUpdate, data?.links?.next);
          return paginatedArray;
        });
      });
    });
  };
}

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// Purchase Stores
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
export const purchase_historyP: Writable<Promise<PaginatedArray<PurchaseHistoryItem>>> = writable();

export async function loadPurchaseHistory(): Promise<void> {
  
  purchase_historyP.set(api
    .GET("/purchases")
    .then(function ({ data, error, response }) {
      return {
        arr: data?.data || [],
        loadMore: buildLoadMoreFunction(purchase_historyP, data?.links?.next),
      };
    }));
}

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// Toast Stores - These are used for global alerts
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
type GlobalToast = {
  hed: string;
  colorClassname?: string;
  noAutoDismiss?: boolean;
}
export const toasts:Writable<Array<GlobalToast>> = writable([]);
export function showToast (t:GlobalToast) {
  toasts.update(ts => {
    ts.push(t);
    return ts;
  })
}


// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// Other...
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  -|

// Automatically wipe in-memory user data when the session expires.
let session_timer: number;
user.subscribe(function handleSessionTimeout($user) {
  clearTimeout(session_timer);
  if ($user?.sess_exp_at) {
    session_timer = callAtTimestamp($user.sess_exp_at, () => {
      user.set(null);
      last_seen_username = "∞";
    });
  }
});
