<script lang="ts">
  // Include our external dependencies.
  // ...

  // Component Props
  export let rows:Array<{}> = [];
</script>
<div data-component="DataTable" class="w-100 overflow-auto">
  {#if rows && rows.length && rows[0]}
  <table class="table table-striped table-bordered small table-sm mb-4">
    <thead>
      <tr>
        {#each Object.keys(rows[0]) as key}
          <th scope="col">{key}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each rows as pv, i}
        <tr>
          {#each Object.values(pv) as v}
            <td>{v}</td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
  {/if}
</div>