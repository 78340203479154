<script lang="ts">
  // Include our external dependencies.
  // ...

  export let loadMore:(()=>Promise<void>) | null | undefined;
  let loader = Promise.resolve();
</script>


<div class="d-flex justify-content-center py-3">
  {#if loadMore}
    {#await loader}
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    {:then} 
      <button type="button" class="btn btn-outline-secondary" on:click={() => loader = loadMore()}>
        Load more
      </button>
    {/await}

  {:else}
  <div class="text-center">
    <span class="text-muted">No more to load</span>
  </div>
  {/if}
</div>