<script lang="ts">
  // Include our external dependencies.
  import { Chart, colors } from "../../lib/chart";
  import { onMount } from "svelte";
  import i18n from "../../lib/i18n";

  // Component Props/API
  export let chart_data;
  export let field_name:string;

  // Prepare the Chart.js graph
  let canvasEl:HTMLCanvasElement;

  onMount(() => {
    const catd = chart_data.categories[field_name];
    // Format and sort the chart datas.
    const sorted_keys = Object.keys(catd).sort((a, b) => catd[b].__sum - catd[a].__sum);

    const chart = new Chart(
      canvasEl,
      {
        type: 'bar',
        data: {
          labels: sorted_keys,
          datasets: [{
            label: "Page Views",
            data: sorted_keys.map(k => catd[k].__sum),
            backgroundColor: colors,
            // barThickness: 16,
            // categoryPercentage: 1.0,
            // barPercentage: 1.0
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "y",
          plugins: {
            legend: {
              display: false,
            },
            colors: {
              enabled: false,
            },
            title: {
              display: true,
              text: $i18n.t(`doughnut-chart.titles.${field_name}`),
            }
          },
        },
      }
    );

    return () => chart.destroy();
  });

</script>
<style>
  canvas {
    height: 300px;
  }
</style>
<canvas bind:this={canvasEl} />