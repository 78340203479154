<script lang="ts">
  // Include our external dependencies.
  import Toast from "bootstrap/js/dist/toast";
  import { toasts } from "../../lib/store";

  let toastEls:Array<HTMLElement> = [];
  
  $:{ // Instantiate the BS toast JSs
    toastEls.forEach((el) => {
      if (!el) return;
      const bst = Toast.getOrCreateInstance(el);
      if (bst && !bst.isShown()) bst.show();
    });
  }

  function removeToast (index:number) {
    const bst = Toast.getOrCreateInstance(toastEls[index]);
    if (bst) bst.dispose();
    toasts.update(ts => {
      ts.splice(index, 1);
      return ts;
    });
  }
</script>

<style lang="scss">
  @import "bootstrap/scss/functions";
  @import "99dev-bootstrap/scss/variables.scss";
  .global-toasts {
    z-index: $zindex-toast;
  }
</style>

<div aria-live="polite" aria-atomic="true" class="global-toasts position-fixed bottom-0 h-0 w-0 end-0 overflow-visible">
  <div class="toast-container position-relative">
    {#each $toasts as toast, i}
      <div
        class="toast align-items-center border-0 me-3 mb-3 {toast?.colorClassname}"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-bs-autohide={!toast?.noAutoDismiss}
        bind:this={toastEls[i]}
        on:hidden.bs.toast={() => removeToast(i)}
      >
        <div class="d-flex">
          <div class="toast-body">{toast.hed}</div>
          <button
            type="button"
            class="btn-close me-2 m-auto {toast?.colorClassname}"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>
    {/each}
  </div>
</div>
