<script lang="ts">
  // Include our external dependencies.
  import { Chart, colors } from "../../lib/chart";
  import { isoToTimestamp } from "../../lib/time";
  import { onMount } from "svelte";

  // Component Props/API
  export let chart_data;

  // Prepare the Chart.js graph
  let canvasEl:HTMLCanvasElement;
  onMount(() => {
    const chart = new Chart(
      canvasEl,
      {
        type: 'bar',
        data: {
          labels: chart_data.dates.map(isoToTimestamp),
          datasets: [
            {
              label: 'Page Views',
              data: chart_data.totals,
              backgroundColor: colors[0],
              borderColor: colors[0],
            },
            {
              label: 'Visits',
              data: chart_data.categories.pcounts["1"],
              backgroundColor: colors[3],
              borderColor: colors[3],
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              position: "top",
            },
            colors: {
              enabled: false,
            },
            title: {
              display: true,
              text: "Views / Visits",
            }
          },
          scales: {
            x: {
               // @ts-expect-error
              type: "timestack",
              grid: {
                display: false,
              }
            },
            y: {
              // stacked: true,
              beginAtZero: true, // Ensure the Y-axis starts at zero
              ticks: {
                padding: 1,
                backdropPadding: 0,
                showLabelBackdrop: true,
                z: 1,
              },
              grid: {
                display: false,
                drawTicks: false,
              },
              border: {
                display: false,
              }
            },
          }
        },
      }
    );

    return () => chart.destroy();
  });

</script>
<style>
  canvas {
    max-height: 460px;
  }
</style>
<canvas bind:this={canvasEl} />