<script lang="ts">
  // Include our external dependencies.
  import Modal from "bootstrap/js/dist/modal"; // Required for BS data attributes
  import { createEventDispatcher } from 'svelte';
  import { addSite, type Site } from "../../lib/store";
  import MianCode from "../atoms/mian-code.svelte";

  // Cache some environment variables.
  const docs_link = import.meta.env.VITE_99DEV_DOCS_LINK;

  // Instantiate the component event dispatcher
  const dispatch = createEventDispatcher();

  let input_name:string = "";
  let form_is_disabled:boolean = false;
  let created_site: Site | null = null;

  function handleSubmit(event: SubmitEvent) {
    event.preventDefault();
    form_is_disabled = true;
    addSite(input_name).then((site) => {
      created_site = site;
      dispatch("created", site);
    });
    return false;
  }

  function handleModalClose() {
    input_name = "";
    form_is_disabled = false;
    created_site = null;
  }
</script>

<button
  type="button"
  class="btn btn-outline-primary"
  data-bs-toggle="modal"
  data-bs-target="#addSiteModal"
>
  <span>+</span><span class="d-none d-sm-inline">&nbsp;Site</span>
</button>

<!-- + Site Modal-->
<div
  class="modal modal-lg fade"
  id="addSiteModal"
  tabindex="-1"
  aria-labelledby="addSiteModalLabel"
  aria-hidden="true"
  on:hidden.bs.modal={handleModalClose}
>
  <form class="modal-dialog" on:submit={handleSubmit}>
    <div class="modal-content">
      <div class="modal-header">
        <legend class="modal-title fs-5" id="addSiteModalLabel"
          >Add a Site</legend
        >
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <fieldset class="mb-3" disabled={form_is_disabled}>
          <label for="asbc_name_input" class="form-label">Site Name</label>
          <input
            type="text"
            id="asbc_name_input"
            class="form-control"
            placeholder="something.com"
            required
            bind:value={input_name}
          />
          <div class="form-text" id="basic-addon4">You can edit this later</div>
        </fieldset>

        <!-- Success! -->
        {#if created_site}
          <section class="mb-3">
            <h3 class="text-success">{`${created_site.name} is ready!`}</h3>
            <p>
              Your Site UUID is {created_site.uuid}.
            </p>
            <p>
              Place this tracking snippet on each page of your website. See our docs for <a href={docs_link}>installation via NPM</a>.
            </p>
            <MianCode site_uuid={created_site.uuid} />
          </section>
        {/if}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
          >Close</button
        >
        {#if !created_site}
        <button
          type="submit"
          class="btn btn-primary"
          disabled={form_is_disabled}
        >
          {#if !form_is_disabled}
            <span>Save Changes</span>
          {:else}
            <span class="spinner-border spinner-border-sm" aria-hidden="true"
            ></span>
            <span class="visually-hidden" role="status">Loading...</span>
          {/if}
        </button>
        {/if}
      </div>
    </div>
  </form>
</div>
