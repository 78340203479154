<script lang="ts" context="module">
  export enum ViewMode {
    Graph = "Graph",
    Table = "Table",
  }

</script>
<script lang="ts">
  // Include our external dependencies.
  import { createEventDispatcher } from 'svelte';

  // Component Props
  export let view_mode:ViewMode = ViewMode.Graph;

  // Create our event dispatcher
  const dispatch = createEventDispatcher();

  // Generate a Unique Identifier for this component
  const uuid = crypto.randomUUID();

  // Event Handlers
  function handleChange (event) {
    dispatch("viewModeChange", event.target.value);
  }
</script>

<div class="btn-group" role="group" aria-label={uuid}>
  {#each Object.values(ViewMode) as option, i}
    {@const id = ["vms",uuid, i].join("_")}
    <input type="radio" class="btn-check" name={id} {id} autocomplete="off" value={option} on:change={handleChange} checked={view_mode === option}>
    <label class="btn btn-outline-secondary" for={id}>{option}</label>
  {/each}
</div>