// Include our external dependencies.
import { type components } from "./api";

// Expand our Typescript types.
type MianEventSummary = components["schemas"]["MianEventSummary"];
export type MianHeadlines = {
  total_page_views:number;
  total_visits:number;
  total_bounces:number;
}

export function generateChartData(summary:MianEventSummary|null) {
  if (!summary || !summary.segments) return null;

  // loop through all of the entries/days
  return summary.segments.reduce(
    (chart_datas, d, i) => {
      chart_datas.totals[i] = parseInt(d.total_views) || 0;
      chart_datas.total += chart_datas.totals[i];
      chart_datas.dates[i] = d.name;

      // loop through the summary data. (lol how many nested names we've got here!)
      for (let property in d.categories) {
        // put the top level into a "category".
        if (!chart_datas.categories[property]) {
          chart_datas.categories[property] = {};
        }

        // put the inner attributes into the category as an array.
        const category_data = d.categories[property];
        for (let category_data_prop in category_data) {
          // ensure we have data for this category entry.
          if (!chart_datas.categories[property][category_data_prop]) {
            chart_datas.categories[property][category_data_prop] = [];
            chart_datas.categories[property][category_data_prop].__sum = 0;
          }
          chart_datas.categories[property][category_data_prop][i] =
            category_data[category_data_prop];
          chart_datas.categories[property][category_data_prop].__sum +=
            category_data[category_data_prop];
        }
      }
      return chart_datas;
    },
    { total: 0, dates: [], totals: [], categories: {} },
  );
}

export function generateHeadlines(summary:MianEventSummary|null):MianHeadlines {
  const segments = !summary || !summary.segments ? [] : summary.segments;
  const headlines = segments.reduce((acc, segment) => {
    acc.total_page_views += parseInt(segment.total_views) || 0;
    acc.total_visits += parseInt(segment.total_visits) || 0;
    if (segment?.categories?.pcounts) {
      const pcone = parseInt(segment?.categories?.pcounts["1"]) || 0;
      const pctwo = parseInt(segment?.categories?.pcounts["2"]) || 0;
      const bounces = pcone - pctwo;
      acc.total_bounces += bounces;
    }
    return acc;
  }, {
    total_page_views: 0,
    total_visits: 0,
    total_bounces: 0,
  });
  return headlines;
}

export function generatePagesPerSession(chart_data) {
  const keys = Object.keys(chart_data.categories.pcounts).sort((a,b)=>b-a);
  const { pps } = keys.reduce((acc, key) => {
    const sum = chart_data.categories.pcounts[key].__sum;
    const __sum = sum - acc.amountToMinus;
    if (__sum > 0)
      acc.pps[key] = {__sum};
    acc.amountToMinus = sum;
    return acc;
  }, {
    pps: {},
    amountToMinus: 0,
  });
  return { categories:{pps} };
}