<script lang="ts">
  // Include our external dependencies.
  import { type ComponentEvents } from "svelte";
  import { url, route1, route2 } from "mcgregor-utils/svelte-router";
  import { type Site, sitesP } from "../../lib/store";
  import GlobalHeader from "../organisms/global-header.svelte";
  import SitesNavBar from "../organisms/sites-nav-bar.svelte";
  import MianDashboard from "../organisms/mian-dashboard.svelte";
  import AddSiteButton from "../molecules/add-site-button.svelte";
  import Loader from "../atoms/loader.svelte";
  import ViewModeSelector, { ViewMode } from "../atoms/view-mode-selector.svelte";

  // Track the selected site (from the URL and/or loaded sites)
  let active_site_uuid: string = "";
  $: {
    if ($route1) {
      active_site_uuid = $route1;
    } else {
      $sitesP.then(sites => {
        active_site_uuid = $route1 || sites.keys().next().value;
      });
    }
  }

  // Track the selected View Mode (from the URL and components)
  let view_mode:ViewMode;
  $:  view_mode = $route2 === "table" ? ViewMode.Table : ViewMode.Graph;
  function handleViewModeChange (event:ComponentEvents<ViewModeSelector>["viewModeChange"]) {
    const new_view_mode:ViewMode = event.detail;
    window.location.hash = `#/mian/${active_site_uuid}/${new_view_mode.toLowerCase()}`;
  }

  // Helper to generate the page name for analytics.
  function getMianPageName (u:string):string {
    const s = u.split('/');
    if (s[1]) s[1] = ":id";
    return s.join("/");
  }

  // Other Event Handlers

  function handleSiteCreation(event:ComponentEvents<AddSiteButton>["created"]) {
    const site:Site = event.detail;
    if (site && site.uuid) {
      window.location.hash = `#/mian/${site.uuid}/${view_mode.toLowerCase()}`;
    }
  }

  // Site Deletion
  function handleSiteDeletion () {
    // Update the URL
    $sitesP.then(sites => {
      const uuid = [...sites][0][1].uuid || "";
      window.location.hash = `#/mian/${uuid}/${view_mode.toLowerCase()}`;
    });
  }
</script>
<svelte:head>
  <meta name="99dev-page" content={getMianPageName($url)}>
</svelte:head>
<main data-component="HomePage">
  <GlobalHeader url={$url}>
    <AddSiteButton slot="left" on:created={handleSiteCreation} />
  </GlobalHeader>

  {#await $sitesP}
    <!-- Sites loading... -->
    <Loader />
  {:then sites}
    <!-- No Sites Found -->
    {#if !sites.size}
      <div class="text-center pt-5">
        <h1 class="display-1">No Sites Found</h1>
      </div>

    <!-- Sites Loaded and Ready! -->
    {:else}
      <SitesNavBar {sites} {active_site_uuid} />
      <MianDashboard
        {view_mode}
        site={sites.get(active_site_uuid)} 
        on:viewModeChange={handleViewModeChange}
        on:delete={handleSiteDeletion}
      />
    {/if}
  {:catch error}
    <!-- Error loading sites -->
    <p>Something went wrong: {error.message}</p>
  {/await}
</main>
