<script lang="ts">
  // Include our external dependencies.
  import { type Sites } from "../../lib/store";

  // Define our component props
  export let sites:Sites = new Map();
  export let active_site_uuid: string = "";

  function handleSelectChange(event: Event & { currentTarget: EventTarget & HTMLSelectElement; }) {
    window.location.hash = `#/mian/${event.currentTarget.value}`;
  }
</script>

<main data-component="SitesNavBar" class="container-xl">
  {#if sites.size}
    <header class="nav-scroller py-1 py-lg-0">
      <select class="form-select d-lg-none" aria-label="Default select example" on:change={handleSelectChange}>
        {#each sites as [site_uuid, site]}
          <option value={site_uuid} selected={site_uuid===active_site_uuid}>{site.name}</option>
        {/each}
      </select>
      <nav class="nav nav-underline justify-content-between d-none d-lg-flex">
        {#each sites as [site_uuid, site]}
          <a
            class="nav-item nav-link link-body-emphasis"
            class:active={site_uuid===active_site_uuid}
            href={`#/mian/${site_uuid}`}
          >
            {site.name}
          </a>
        {/each}
      </nav>
    </header>
    <hr class="d-none d-lg-block mt-0 mb-2">
  {/if}
</main>
