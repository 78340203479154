/*
  numb.ts -> Numbers! Here are functions to help format and manipulate numbers
*/

export function formatNumberAbbreviation(num: number): string {
  if (num >= 1000 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'k';
  } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
  } else {
      return num.toString();
  }
}

export function formatMoney(value: number, locale: string = 'en-US', currency: string = 'USD'): string {
  return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0, // No decimal places
      maximumFractionDigits: 2  // No decimal places
  }).format(value/100);
}