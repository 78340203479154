// Include external dependencies
import "99dev-bootstrap/css/custom.css";
import * as Sentry from "@sentry/svelte";
import * as analytics from "@99devco/analytics";
import ColorSchemeSwitcher from "99dev-bootstrap/js/wc-color-scheme-switcher";
import { startHashRouting } from "mcgregor-utils/svelte-router";
import { user } from "./lib/store";
import { parseAuthCookie } from "./lib/api";
import App from './components/App.svelte';

// Read environment variables
const sentry_dsn = import.meta.env.VITE_99DEV_SENTRY_DSN
const analytics_uuid = import.meta.env.VITE_99DEVAPPANALYTICSUUIDS;
const analytics_url = import.meta.env.VITE_99DEVAPIURL;

// Initialize the color scheme
ColorSchemeSwitcher.initDOM();

// Initialize runtime error reporting w/Sentry
if (sentry_dsn) {
  Sentry.init({
    dsn: sentry_dsn,
    integrations: [],
  });
}

// Initialize the user (from cookie), hash routing, and Svelte app
user.set(parseAuthCookie());
startHashRouting();
const app = new App({
  target: document.getElementById('app'),
});

// Initialize 99dev (meta yo) analytics
analytics.init(analytics_uuid, {
  nav_type:"hash",
  api_url: analytics_url,
});
analytics.watch();

export default app
