<script lang="ts">
  // Include our external dependencies.
  import Jumbotron from "../atoms/jumbotron.svelte";
  import MianCode from "../atoms/mian-code.svelte";

  // Expand environment variables
  const docs_link = import.meta.env.VITE_99DEV_DOCS_LINK;

  // Component Props
  export let className = "";
  export let site_uuid = "";

  // Internals
  let isSnippetDismissed:boolean;
  $:storage_key = ["nfdism",site_uuid].join("_");
  $:isSnippetDismissed = !!window.localStorage.getItem(storage_key);

  function handleDismiss () {
    isSnippetDismissed = true;
    window.localStorage.setItem(storage_key, "1");
  }
</script>

<div class="my-3 {className}">
  {#if isSnippetDismissed}
    <div class="container-xxl mt-4 text-center">
      <h1 class="text-muted">No Page Views Found</h1>
    </div>
  {:else}
    <Jumbotron
      hed="Add the tracking snippet"
      dek={`Place this snippet on your website and immediately see traffic here. For more installation and configuration options, <a href='${docs_link}'>see the docs</a>.`}
      onDismiss={handleDismiss}
    >
      <MianCode site_uuid={site_uuid} />
    </Jumbotron>
  {/if}
</div>
