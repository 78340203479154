<script lang="ts">
  // Include our external dependencies.
  import Dropdown from "bootstrap/js/dist/dropdown";
  import { logout, user } from "../../lib/store";
  import LogoSVG from "99dev-bootstrap/assets/99dev_logo_pink_resp_text.svg?raw";

  // Expand environment variables
  const www_url = import.meta.env.VITE_99DEVWWWURL;
  const demo_url = import.meta.env.VITE_99DEVDEMOURL;
  const policies_url = import.meta.env.VITE_99DEVPOLICIESURL;
  const support_href = import.meta.env.VITE_99DEV_SUPPORT_LINK;
  const docs_link = import.meta.env.VITE_99DEV_DOCS_LINK;

  // component props
  export let logo_svg = LogoSVG;
  export let url: string = "";
  $: profile_link =
    url.indexOf("mian") === 0 ? `#/profile?back-to-site=${url}` : "#/profile";

  // DOM Event Handlers
  async function handleLogOut(event: MouseEvent) {
    await logout();
    window.location.hash = "";
    return false;
  }
</script>

<header class="container-xl lh-1 pt-3 pb-1">
  <div class="row justify-content-between align-items-center">
    <div class="col">
      <slot name="left"></slot>
    </div>
    <div class="col-auto col-md text-center">
      <a class="header-logo link-body-emphasis text-decoration-none" href="#/">
        {@html logo_svg}
      </a>
    </div>
    <div class="col justify-content-end d-flex">
      <div class="d-flex nav-item align-items-center">
        <color-scheme-switcher btn-class="btn btn-sm btn-link link-body-emphasis"></color-scheme-switcher>
      </div>
      <slot name="right">
        <div class="d-flex justify-content-end">
          <div class="navbar-nav">
            <div class="nav-item dropdown">
              <button
                class="btn dropdown-toggle px-1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {$user?.username}
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li><a class="dropdown-item" href={profile_link}>Profile</a></li>
                <li>
                  <button
                    type="button"
                    class="dropdown-item"
                    on:click={handleLogOut}>Log Out</button
                  >
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li class="d-flex">
                  <a
                    class="align-items-center d-flex dropdown-item justify-content-between"
                    href={docs_link}
                    >Docs</a
                  >
                </li>
                <li>
                  <a
                    class="align-items-center d-flex dropdown-item justify-content-between"
                    href={policies_url}
                    >Policies</a
                  >
                </li>
                <li>
                  <a
                    class="align-items-center d-flex dropdown-item justify-content-between"
                    href={demo_url}
                    >Demo App</a
                  >
                </li>
                <li class="d-flex">
                  <a
                    class="align-items-center d-flex dropdown-item justify-content-between"
                    href={www_url}
                    >About 99dev</a
                  >
                </li>
                <li>
                  <a
                    class="align-items-center d-flex dropdown-item justify-content-between"
                    href={support_href}
                    >Contact Support</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </slot>
    </div>
    <div class="col-12 d-none d-lg-block">
      <hr class="mb-0 mt-2">
    </div>
  </div>
</header>

<style>
  .header-logo > :global(svg) {
    height: 2.25em;
    width: auto;
  }
</style>
